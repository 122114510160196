import createAction from 'redux-actions/lib/createAction';
import axios from 'axios';
import getToken from 'shared/utils/user/getToken';
import errorLogger from 'shared/utils/errorLogger';
import { cancelRefreshToken } from 'shared/utils/user/tokenRefresh';
import { close } from 'shared/modules/notifications/websocket/websocketListener';
import { safeLocalStorage, safeSessionStorage } from 'shared/utils/safeStorage';
import { ALLOWED_DOMAINS } from 'shared/consts';

export const USER_LOGOUT = 'USER_LOGOUT';
export const logoutAction = createAction(USER_LOGOUT);

const removeToken = async token => {
  if (!token) {
    return;
  }
  try {
    await axios.delete(AppRouting.generate('api_token_invalidate'), {
      headers: {
        'X-Viamobile-Token': token,
      },
    });
  } catch (ex) {
    errorLogger(ex);
  }
};

const logout =
  (remove = true, payload) =>
  dispatch => {
    cancelRefreshToken();
    close(dispatch);
    // save token
    const token = getToken();
    if (remove) {
      removeToken(token);
    }
    try {
      // clear storage data
      safeSessionStorage.removeItem('token');
      safeLocalStorage.removeItem('token');
      safeLocalStorage.removeItem('expires');
      safeSessionStorage.removeItem('expires');
      safeLocalStorage.removeItem('rememberMe');
      safeSessionStorage.removeItem('company_id');
      // localStorage.removeItem('company_id');
    } catch (ex) {
      // storage errors
      console.log(ex);
    }
    try {
      const redirect = safeLocalStorage.getItem('logoutRedirect');

      if (redirect && token) {
        const parsedUrl = new URL(redirect);
        const isUrlValidated = ALLOWED_DOMAINS.includes(parsedUrl.hostname);
        if (isUrlValidated) {
          dispatch(logoutAction(payload));
          safeLocalStorage.removeItem('logoutRedirect');
          window.location.href = redirect;
        }
        safeLocalStorage.removeItem('logoutRedirect');
      }
      dispatch(logoutAction(payload));
    } catch (error) {
      console.error(error);
    }
  };

export default logout;
