/* eslint-disable no-useless-escape */
import parse from 'url-parse';
import getEnv, { checkEnv } from './getEnv';
import theme from './theme';

const APP_ENV = getEnv();

// TODO: move this check server side
const FORCE_DEV_KEY = '7zx10hS!xmf231=';

const isDevForced = () => {
  if (typeof window === 'undefined' || !window.location) {
    return false;
  }
  const url = parse(window.location.href, true);
  return url.query.dev === FORCE_DEV_KEY;
};

export const QUERIES = {
  lg: `(min-width: ${theme.value.breakpoints.md}em) and (max-width: ${
    theme.value.breakpoints.lg - 0.0625
  }em)`,
  md: `(min-width: ${theme.value.media.fluid}em) and (max-width: ${
    theme.value.breakpoints.md - 0.0625
  }em)`,
  sm: `(max-width: ${theme.value.media.fluid}em)`,
};

export const IS_DEV = checkEnv('IS_DEV') || isDevForced();

export const DEFAULT_UPLOAD_SIZE = 15;
export const DEFAULT_UPLOAD_SIZE_BYTES = DEFAULT_UPLOAD_SIZE * 1024 * 1024;
export const DEFAULT_UPLOAD_SIZE_STRING = `${DEFAULT_UPLOAD_SIZE}MB`;

export const NAIS_START = new Date('2016-04-01');

export const SUBDOMAINS = ['dev', 'stage', 'test', 'app'];

export const APP_REGEXP = new RegExp(
  `(${SUBDOMAINS.join('|')})\.nais\.(pl|co)`,
);

export const PER_PAGE_OPTIONS = [
  5,
  10,
  25,
  50,
  IS_DEV && 100,
  IS_DEV && 500,
].filter(val => !!val);

export const IS_PROD = APP_ENV === 'prod';

export const NOT_PROD = IS_DEV && !IS_PROD;

export const MAX_Z_INDEX = 2147483647;

export const ALLOWED_DOMAINS = [
  'app.nais.pl',
  'test.nais.pl',
  'dev.nais.pl',
  'stage.nais.pl',
];
