import { UserData } from 'shared/types/User';
import { safeLocalStorage, safeSessionStorage } from 'shared/utils/safeStorage';
import uniqBy from 'lodash/uniqBy';
import getUserColor from 'shared/utils/getUserColor';

export interface LocalUser {
  id: number;
  firstName: string;
  lastName: string;
  avatar: string | null;
  login: string;
  color: string;
}

export type LocalUsers = LocalUser[];

const STORAGE_SAVE = '_lu';

export const URL_LOGIN = 'urlLogin';

const getUser = (userData: UserData): LocalUser => ({
  id: userData.id,
  firstName: userData.firstName,
  lastName: userData.lastName,
  avatar: userData.normalAvatar,
  login: userData.login,
  color: getUserColor(userData),
});

const getPersistedUsers = () => {
  const base = safeLocalStorage.getItem(STORAGE_SAVE);
  if (!base) {
    return [] as LocalUsers;
  }

  try {
    const string = decodeURIComponent(window.atob(base));
    return JSON.parse(string) as LocalUsers;
  } catch (ex) {
    return [] as LocalUsers;
  }
};

const persist = (users: LocalUsers) => {
  safeLocalStorage.setItem(
    STORAGE_SAVE,
    window.btoa(encodeURIComponent(JSON.stringify(users))),
  );
};

const addUser = (user: UserData) => {
  const urlLogin = safeSessionStorage.getItem(URL_LOGIN) === '1';
  if (urlLogin) {
    console.warn('[localUsers.add] urlLogin is set, not adding user');
    return;
  }
  const savedUsers = getPersistedUsers();
  const newUsers = uniqBy([getUser(user), ...savedUsers], 'id');
  persist(newUsers);
};

const removeUser = (id: number) => {
  const users = getPersistedUsers();
  persist(users.filter(user => user.id !== id));
};

const localUsers = {
  add: addUser,
  remove: removeUser,
  get: getPersistedUsers,
};

export default localUsers;
